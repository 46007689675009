<template>
  <v-container class="header-inside pa-0" fluid>
    <p style="height:77px;" />
    <v-layout>
      <v-app-bar scroll-behavior="--hide --elevate" height="77">
        <div class="box" direction="column" svalign="start" extent="w-100 h-100">
          <component
            v-if="resolution.scroll > 100"
            :is="AdvertiseTee"
            animation="fade-in-top"
          />
          <div class="box" bg="detault" valign="center" extent="w-100 h-100">
            <div wpd="xs" :scale="resolution.scroll > 100 ? `9` : ``">
              <div
                v-if="user.login"
                cursor="pointer"
                @click="$globalFunctions.pageLayout('panel', !layout.panel)"
              >
                <v-avatar
                  :image="user?.thumbnail"
                  :color="globalVariables.userset.color"
                  class="panel"
                >
                  <span fsize="sm">{{ user?.nickname?.slice(0, 1) }}</span>
                </v-avatar>
              </div>
              <button
                v-else
                color="3xl"
                fsize="lg"
                :title="$t('menu')"
                area-label="Open Side Panel"
                @click="$globalFunctions.pageLayout('panel', !layout.panel)"
              >
                <v-icon icon="mdi-menu" variant="plain" />
              </button>
            </div>
            <div flex="1" class="box" halign="center">
              <Logo
                :type="`company`"
                :longwidth="`160`"
                :scale="resolution.scroll > 100 ? `9` : ``"
              />
            </div>
            <div wpd="xs" :scale="resolution.scroll > 100 ? `9` : ``">
              <component
                :is="Search"
              />
            </div>
          </div>
        </div>
      </v-app-bar>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'
import { useGlobalStore } from '~/store/global'
// import Search from '~/component/element/search.vue'
// import AdvertiseTee from '~/component/advertise/tee.vue'

export default {
  setup () {

    // 글로벌 스토어
    const { user } = storeToRefs(useAuthStore())
    const { layout } = storeToRefs(useGlobalStore())
    const { resolution } = storeToRefs(useGlobalStore())

    // 메모리 비우기
    function restore () {
      Search.value = null
      AdvertiseTee.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const Search: any = shallowRef(null)
    const AdvertiseTee: any = shallowRef(null)
    const loadComponent = async () => {
      const Search_load = await import('~/component/element/search.vue')
      const AdvertiseTee_load = await import('~/component/advertise/tee.vue')
      Search.value = Search_load.default || Search_load
      AdvertiseTee.value = AdvertiseTee_load.default || AdvertiseTee_load
    }
    loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      user,
      layout,
      resolution,

      // 컴포넌트
      Search,
      AdvertiseTee
    }
  }
}
</script>
